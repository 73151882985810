import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductListing from "./containers/ProductListing";
import Header from "./containers/Header";
import "./App.css";
import ProductDetails from "./containers/ProductDetails";
import ReactGA from 'react-ga';
  const TRACKING_ID = "G-L410ZM9KCC"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={ProductListing} />
          <Route path="/product/:productId" component={ProductDetails} />
          <Route>404 Not Found!</Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
