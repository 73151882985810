import React, { useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../redux/actions/productsActions";
import ProductComponent from "./ProductComponent";

const ProductPage = () => {
  const products = useSelector((state) => state.allProducts.products);
  const dispatch = useDispatch();
  const fetchProducts = async () => {
    const response = await axios
       //.get("https://fakestoreapi.com/products")
      // .get("https://45.63.0.98:5000/product")https://zf6867vlrd.execute-api.us-east-1.amazonaws.com/prod/products/
      .get("https://hev5pkxaag.execute-api.us-east-1.amazonaws.com/allprod/products")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setProducts(response.data.products));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  console.log("Products :", products);
  return (
    <div className="ui grid container">
      <ProductComponent />
    </div>
  );
};

export default ProductPage;
