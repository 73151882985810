import React from "react";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="ui fixed menu">
      <div className="ui container center">
        {/* <h2><a href="https://www.grtdeal.com">Great Deals</a></h2> */}
        <Link to='/'><h2>Great Deals</h2></Link>
        {/* <img src="/great-deals-logo.png" alt="image" /> */}
        {/* <img style={{ width: 251, height: 95 }} src="/great-deals-logo.png" alt="React Logo" /> */}
      </div>
    </div>
  );
};

export default Header;
